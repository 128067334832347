import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectSettingsUpdate, useGetSettings, useUpdateSettings } from '@api/endpoints/settings'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { Box } from '@mui/system'
import { Button } from '@controls/Button'
import { FormTextFieldStyled, FormTranslatedTextField, useFormZod } from '@controls/Form'

const FormValues = ProjectSettingsUpdate
type FormValues = ProjectSettingsUpdate

export const ProjectSettingsPage: React.FC = () => {
  const defaultErrorHandler = useAdminDefaultErrorHandler()
  const { t } = useTranslation()

  const getSettings = useGetSettings({ onError: defaultErrorHandler })
  const setSettings = useUpdateSettings({ onError: defaultErrorHandler })

  const onSubmit = (values: FormValues): void => {
    setSettings.mutate(values)
  }

  const form = useFormZod({
    schema: FormValues
  })

  const isLoading = getSettings.isFetching || setSettings.isLoading

  React.useEffect(() => {
    if (getSettings.data) {
      form.reset(getSettings.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSettings.data])

  return (
    <div>
      <h1>{t('pages.admin.settings.title')}</h1>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" rowGap={2} mb={4}>
          <FormTextFieldStyled
            name="webPage"
            form={form}
            label={t('pages.admin.settings.form.field.web_page')}
            disabled={isLoading}
          />
          <FormTranslatedTextField
            form={form}
            nameEng="accessToProperty.eng"
            nameEst="accessToProperty.est"
            nameRus="accessToProperty.rus"
            label={t('pages.admin.settings.form.field.access_to_property')}
            disabled={isLoading}
            multiline
          />
          <FormTranslatedTextField
            form={form}
            nameEng="accessInProperty.eng"
            nameEst="accessInProperty.est"
            nameRus="accessInProperty.rus"
            label={t('pages.admin.settings.form.field.access_in_property')}
            disabled={isLoading}
            multiline
          />
          <FormTranslatedTextField
            form={form}
            nameEng="encumbrances.eng"
            nameEst="encumbrances.est"
            nameRus="encumbrances.rus"
            label={t('pages.admin.settings.form.field.encumbrances')}
            disabled={isLoading}
            multiline
          />
          <FormTranslatedTextField
            form={form}
            nameEng="developer.eng"
            nameEst="developer.est"
            nameRus="developer.rus"
            label={t('pages.admin.settings.form.field.developer')}
            disabled={isLoading}
          />
          <FormTranslatedTextField
            form={form}
            nameEng={'mainContractor.eng'}
            nameEst={'mainContractor.est'}
            nameRus={'mainContractor.rus'}
            label={t('pages.admin.settings.form.field.main_contractor')}
            disabled={isLoading}
          />
          <FormTranslatedTextField
            form={form}
            nameEng={'constructionTimeline.eng'}
            nameEst={'constructionTimeline.est'}
            nameRus={'constructionTimeline.rus'}
            label={t('pages.admin.settings.form.field.construction_timeline')}
            disabled={isLoading}
          />
          <Box display="flex" columnGap={2} mt={2}>
            <Button disabled={isLoading} type="submit">
              {t('global.buttons.submit')}
            </Button>
          </Box>
        </Box>
      </form>
    </div>
  )
}
